import React from 'react'

// Elements
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'


const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
  location
}) => {
  return (
    <Layout location={location}>
      <SEO
        yoast={yoast}
        path={path}
      />

      <FlexContentHandler location={location} fields={acf.content_page} />
      
    </Layout>
  )
}

export default HomeTemplate